// 引入api
import { fun_GetAction, fun_PostAction } from "@/api";
// 引入加密方法
import "@/utils/security.js";
// 默认导出
export default {
    // 数据存储对象
    data: () => {
        return {
            // 抽屉状态
            drawerStatus: false,
            // 抽屉确定按钮loading状态
            loading: false,
            // 查询表单字段
            form: {
                id: null, // id
                serviceName: null, // 服务名称
                serviceCode: null, // 服务编号
                serviceAmount: null, // 服务金额
                remarks: null //备注
            },
            // 表单提交返回的错误消息
            errRules: {
                serviceName: null, // 服务名称
                serviceCode: null, // 服务编号
                serviceAmount: null, // 服务金额
                remarks: null //备注
            },
            // 详情表单基础数据
            formConfigList: [{
                    label: "服务名称：",
                    value: "serviceName",
                    type: "input",
                    required: true,
                    rule: {
                        required: true,
                        message: "请输入服务名称",
                        trigger: "blur"
                    }
                },
                {
                    label: "服务编号：",
                    value: "serviceCode",
                    type: "input",
                    required: true,
                    rule: {
                        required: true,
                        message: "请输入服务编号",
                        trigger: "blur"
                    }
                },
                {
                    label: "服务金额：",
                    value: "serviceAmount",
                    type: "input",
                    required: true,
                    rule: {
                        required: true,
                        message: "请输入服务金额",
                        trigger: "blur"
                    }
                },
                {
                    label: "备注：",
                    value: "remarks",
                    type: "textarea",
                    required: true,
                    rule: {
                        required: true,
                        message: "请输入备注",
                        trigger: "blur"
                    }
                }
            ],
            // 加密key
            key: null
        };
    },

    // 模板编译完成
    mounted() {
        // 获取加密key
        this.fun_GetRSAKey();
    },

    // 自定义方法
    methods: {
        /**
         * 获取详情
         * @author 滕威
         */
        async fun_GetDetails(id) {
            this.form.id = id;
            // 请求，获取结果集
            let res = await fun_PostAction("/serviceDictionary/detail?id=" + id);
            // 解构res.data
            const { result, data, msg } = res.data;
            // 判断请求是否成功
            if (result) {
                // 回显表单
                this.form = data;
            } else {
                // 请求失败，提示错误信息
                this.$message.error(msg);
            }
        },
        /**
         * 表单提交
         * @author 滕威
         */
        fun_SubmitForm() {
            // 校验
            this.$refs.form.$refs.enInform.validate(valid => {
                // 校验通过
                if (valid) {
                    this.form.platformName = this.$refs.form.$el[0].value;
                    // 打开确定按钮loading状态
                    this.loading = true;
                    // 发起请求，获取结果集
                    fun_PostAction("/serviceDictionary/saveOrUpdate", this.form)
                        .then(res => {
                            // 解构res.data
                            const { result, msg, data } = res.data;
                            // 判断请求是否成功
                            if (result) {
                                // 请求一览查询接口
                                this.$parent.fun_GetList();
                                // 关闭弹窗
                                this.drawerStatus = false;
                                // 提示信息
                                this.$message.success(msg);
                            } else {
                                // 如果存在返回数据
                                if (data != null) {
                                    // 定义rules为一个随机数
                                    this.errRules = Math.random();
                                    // 执行延迟方法
                                    this.$nextTick(() => {
                                        // 提示
                                        this.$message.error("请按提示修改");
                                        // 赋值错误消息
                                        this.errRules = data;
                                    });
                                } else {
                                    // 请求失败，提示信息
                                    this.$message.error(msg);
                                }
                            }
                            // 取消确定按钮loading状态
                            this.loading = false;
                        })
                        .catch(() => {
                            // 取消确定按钮loading状态
                            this.loading = false;
                        });
                } else {
                    // 校验不通过，返回false，页面给出提示
                    return false;
                }
            });
        },
        /**
         * 抽屉关闭时执行
         * @author 滕威
         */
        fun_DrawerClose() {
            // 清空所属机构和角色下拉框
            // 清除表单验证和数据
            this.$refs.form.$refs.enInform.resetFields();
            // 关闭时，清除勾选
            this.$parent.$refs.list.$refs.multipleTable.clearSelection();
        },
        /**
         * 选择下拉框事件
         * @param {e} 选择的value值
         * @param {val} 下拉框的字段
         * @author 滕威
         */
        fun_GetSelectVal(e, val) {
            // 遍历查询表单对象
            for (const key in this.form) {
                // 判断key和val是否相同
                if (key == val) {
                    // 相同则复制给表单
                    this.form[key] = e;
                }
            }
        },
        /**
         * 获取加密key接口
         * @author 滕威
         */
        fun_GetRSAKey() {
            // 调用获取加密参数接口
            fun_PostAction("/getRSAKey").then(res => {
                // 解构res.data
                const { result, data } = res.data;
                // 判断请求是否成功
                if (result) {
                    // 获取加密key
                    this.key = window.RSAUtils.getKeyPair(
                        data.exponent,
                        "",
                        data.modulus
                    );
                }
            });
        }
    }
};